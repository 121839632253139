// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ABOUT_ME = "P\u00e1r slov o tebe";
export const ADD_AVATAR = "Pridaj profilov\u00fa fotku";
export const AVATAR_PHOTO_RULES_LINE1 = "poru\u0161uj\u00fa z\u00e1kony";
export const AVATAR_PHOTO_RULES_LINE2 = "s\u00fa neslu\u0161n\u00e9";
export const AVATAR_PHOTO_RULES_LINE3 = "maj\u00fa politick\u00fd \u010di n\u00e1bo\u017eensk\u00fd obsah";
export const AVATAR_PHOTO_RULES_LINE4 = "obsahuj\u00fa reklamu";
export const AVATAR_PHOTO_RULES_TITLE = "Pros\u00edm, vyhni sa obr\u00e1zkom, ktor\u00e9:";
export const BOY = "Chlap\u010dek";
export const CHANGE_AVATAR = "Zme\u0148 profilov\u00fa fotku";
export const CHILD_NAME = "Bude sa vola\u0165";
export const DELETE_AVATAR = "Zma\u017e profilov\u00fa fotku";
export const DO_NOT_PLAN_IN_CLOSE_FUTURE = "V bl\u00edzkej bud\u00facnosti b\u00e1bo nepl\u00e1nujem";
export const DO_NOT_WANT_TO_TELL = "Neprezrad\u00edm :-)";
export const ENTER_BIRTH_DATE = "D\u00e1tum narodenia";
export const FEMALE = "\u017eena";
export const GIRL = "Diev\u010datko";
export const IT_WILL_BE_SURPRISE = "Nech\u00e1me sa prekvapi\u0165";
export const MALE = "mu\u017e";
export const OR_POSSIBLE_VARIANTS_IF_YOU_ARE_NOT_SURE = "(alebo mo\u017en\u00e9 varianty, ak e\u0161te nevie\u0161 na 100%)";
export const PREGNANCY_STATE = "\u010cak\u00e1\u0161 b\u00e1b\u00e4tko?";
export const PREGNANT = "\u00c1no!";
export const PREGNANT_WITH = "Bude to";
export const REMOVE_PHOTO = "Zru\u0161 fotku";
export const RESIDENCE_CITY = "Bydlisko - mesto / obec";
export const RESIDENCE_COUNTY = "Bydlisko - okres";
export const SETTINGS_BIRTH_DATE_WE_ONLY_SHOW_AGE = "D\u00e1tum narodenia nie je nikde zverejnen\u00fd, vypo\u010d\u00edtame z neho tvoj vek. Vek je zverejnen\u00fd v profile";
export const SETTINGS_DUE_DATE = "Term\u00edn - predpokladan\u00fd d\u00e1tum p\u00f4rodu";
export const SETTINGS_DUE_DATE_INFO = "(1x t\u00fd\u017edenne ti po\u0161leme inform\u00e1cie o tvojom tehotenstve na e-mail a z\u00edska\u0161 pr\u00edstup do tehotenskej skupiny. Na tvojom profile sa prv\u00e9 3 mesiace od pribli\u017en\u00e9ho za\u010diatku tehotenstva nezobrazuje inform\u00e1cia, \u017ee si tehotn\u00e1)";
export const SIGNUP_LOCATION_COUNTRY = "Bydlisko - krajina";
export const TRIPLETS = "Troji\u010dky";
export const TWINS_BOY_AND_GIRL = "Dvoji\u010dky: chlapca a diev\u010da";
export const TWINS_BOYS = "Dvoji\u010dky: chlapcov";
export const TWINS_GIRLS = "Dvoji\u010dky: diev\u010dat\u00e1";
export const TWINS_SURPRISE = "Dvoji\u010dky: nevieme pohlavie";
export const WE_ARE_TRYING_TO_CONCEIVE = "Nie, ale sna\u017e\u00edme sa...";
export const WE_DONT_KNOW = "Zatia\u013e nevieme";
