// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const GROUPS_JOIN_SAME_DATE_DOMAIN_GROUP_name = "Pridaj sa do term\u00ednovej skupiny %(name)s";
export const lo_TO_up = "%(lo)s a\u017e %(up)s";
export const number_DAYS = ["%(number)s de\u0148", "%(number)s dni", "%(number)s dn\u00ed"];
export const number_WEEKS = ["%(number)s t\u00fd\u017ede\u0148", "%(number)s t\u00fd\u017edne", "%(number)s t\u00fd\u017ed\u0148ov"];
export const PREGNANCY_AVERAGE_FETAL_LENGTH = "Priemern\u00e1 d\u013a\u017eka plodu";
export const PREGNANCY_AVERAGE_FETAL_WEIGHT = "Priemern\u00e1 hmotnos\u0165 plodu";
export const PREGNANCY_CALCULATOR_READ_MORE_ABOUT_week_IN_WIKI = "Pre\u010d\u00edtaj si viac o %(week)s. t\u00fd\u017edni tehotenstva";
export const PREGNANCY_CALCULATOR_SUBSCRIBE_PREGNANCY_NEWSLETTER = "Prihl\u00e1s sa do newsletteru: Tehotenstvo t\u00fd\u017ede\u0148 po t\u00fd\u017edni";
export const PREGNANCY_CALCULATOR_SUBSCRIBE_PREGNANCY_NEWSLETTER_TEXT = "Zaregistruj sa na tento tehotensk\u00fd newsletter a ka\u017ed\u00fd t\u00fd\u017ede\u0148 tvojho tehotenstva dostane\u0161 emailom praktick\u00e9 a lek\u00e1rom overen\u00e9 inform\u00e1cie o tom, \u010do \u0165a \u010dak\u00e1 a ako sa vyv\u00edja tvoje die\u0165atko.";
export const PREGNANCY_DAYS_TILL_DUE_DATE = "Po\u010det dn\u00ed do p\u00f4rodu";
export const PREGNANCY_DUE_DATE_RANGE = "\u010casov\u00e9 rozmedzie pre p\u00f4rod (38+0 a\u017e 42+0)";
export const PREGNANCY_NEWSLETTER_EXPERIMENT_TEXT_ENDING = "Zisti, ak\u00e9 lek\u00e1rske prehliadky je vhodn\u00e9 v tomto t\u00fd\u017edni absolvova\u0165 v na\u0161om bezplatnom tehotenskom newsletteri!";
export const PREGNANCY_NEWSLETTER_week_NEXT_WEEK = "Vie\u0161, \u010do \u0165a \u010dak\u00e1 v bud\u00facom (%(week)s.) t\u00fd\u017edni?";
export const PREGNANCY_WEEK = "T\u00fd\u017ede\u0148 tehotenstva";
export const PREGNANCY_WEEK_DAY_MEDICAL = "Lek\u00e1rske ozna\u010denie doby tehotenstva";
export const SIGNUP_AND_JOIN_GROUP = "Registruj sa a pridaj sa do skupiny";
export const SIGNUP_DUE_DATE = "Term\u00edn p\u00f4rodu";
export const TRIMESTER = "Trimester";
