// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const HOMEPAGE_POST_TYPE_ARTICLE = "\u010dl\u00e1nok";
export const HOMEPAGE_POST_TYPE_CONTEST = "S\u00fa\u0165a\u017e";
export const HOMEPAGE_POST_TYPE_COUNSELLING = "Z poradne";
export const HOMEPAGE_POST_TYPE_COUNSELLING_TOPIC = "Porad\u0148a";
export const HOMEPAGE_POST_TYPE_DISCUSSION = "diskusia";
export const HOMEPAGE_POST_TYPE_EXPERIENCE = "sk\u00fasenos\u0165";
export const HOMEPAGE_POST_TYPE_FORUM_TOPIC = "F\u00f3rum";
export const HOMEPAGE_POST_TYPE_FROM_INTERNET = "Z internetu:";
export const HOMEPAGE_POST_TYPE_PHOTOBLOG_ALBUM = "album";
export const HOMEPAGE_POST_TYPE_PHOTOBLOG_ARTICLE = "\u010dl\u00e1nok";
export const HOMEPAGE_POST_TYPE_REVIEW = "recenzia";
export const HOMEPAGE_POST_TYPE_REVIEW_ARTICLE = "Z centra recenzi\u00ed";
export const HOMEPAGE_POST_TYPE_SHORT_MESSAGE = "Kr\u00e1tka spr\u00e1va";
export const HOMEPAGE_POST_TYPE_YOUTUBE_MK = "YouTube Modr\u00fd kon\u00edk";
