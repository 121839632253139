// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const EMAIL = "Email";
export const FORMS_DEFAULT_REQUIRED_FIELD_ERROR = "Toto pole je povinn\u00e9.";
export const NEWSLETTER_SUBSCRIBE = "Prihlasujem sa na odber";
export const number_WEEKS = ["%(number)s t\u00fd\u017ede\u0148", "%(number)s t\u00fd\u017edne", "%(number)s t\u00fd\u017ed\u0148ov"];
export const PREGNANCY_NEWSLETTER_NOTE_FIRST_WEEKS = "Pokia\u013e si v 1. \u2013 4. t\u00fd\u017edni, ulo\u017e\u00edme si \u0165a a prv\u00fd e-mail ti pr\u00edde a\u017e v 5. t\u00fd\u017edni. Na\u0161e tehotensk\u00e9 newslettre za\u010d\u00ednaj\u00fa toti\u017e a\u017e 5. t\u00fd\u017ed\u0148om.";
export const SIGNUP_DUE_DATE = "Term\u00edn p\u00f4rodu";
export const WHAT_WEEK_OF_PREGNANCY_ARE_YOU = "V akom si t\u00fd\u017edni tehotenstva?";
export const YOUR_REGISTRATION_EMAIL = "Tvoj registra\u010dn\u00fd e-mail";
