// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const AUTHOR = "autor";
export const BADGE_ACCESSORIES = "Pr\u00edslu\u0161enstvo";
export const BADGE_EXPERT_TIPS = "Tipy ko\u010d\u00edkovej poradkyne";
export const BADGE_HOWTO = "Ako na to";
export const BADGE_NEW = "Nov\u00e9";
export const BADGE_NEWS = "Novinky";
export const BADGE_OFFICIAL_PROFILE = "OFICI\u00c1LNY PROFIL";
export const BADGE_REDACTION_RECOMMENDATIONS = "Redak\u010dn\u00e9 v\u00fdbery";
export const BADGE_VIDEO = "Video";
export const BRAND_AMBASSADOR = "AMBAS\u00c1DORKA";
export const INACTIVE_USER = "Neakt\u00edvna";
export const REDACTION_REVIEW = "Redak\u010dn\u00e1 recenzia";
export const SHOPPING_GUIDE = "N\u00e1kupn\u00fd sprievodca";
export const TOPIC_MODERATOR = "Moder\u00e1tor";
export const VERIFIED_ORGANIZATION_BADGE = "Overen\u00e1 organiz\u00e1cia";
